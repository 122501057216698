import * as yup from 'yup'
import { version } from '../package.json'

interface AppConfig {
  backOfficeAuthUrl: string
  launchDarklyClientId: string
  segmentKey: string
  sentryAuthToken: string
  sentryDsn: string
  sentryOrg: string
  sentryProject: string
  sentryTraceSampleRate: number
  serviceAggregationsUrl: string
  serviceBackOfficeUrl: string
  serviceCalendarsUrl: string
  serviceInstanceUrl: string
  serviceNylasUrl: string
  serviceRequestUrl: string
  serviceVisitorsUrl: string
  version: string
}

const ConfigSchema: yup.ObjectSchema<AppConfig> = yup.object({
  backOfficeAuthUrl: yup.string().url().required(),
  launchDarklyClientId: yup.string().required(),
  segmentKey: yup.string().required(),
  sentryAuthToken: yup.string().length(64).required(),
  sentryDsn: yup.string().url().required(),
  sentryOrg: yup.string().required(),
  sentryProject: yup.string().required(),
  sentryTraceSampleRate: yup.number().min(0).max(1).required(),
  serviceAggregationsUrl: yup.string().url().required(),
  serviceBackOfficeUrl: yup.string().url().required(),
  serviceCalendarsUrl: yup.string().url().required(),
  serviceInstanceUrl: yup.string().url().required(),
  serviceNylasUrl: yup.string().url().required(),
  serviceRequestUrl: yup.string().url().required(),
  serviceVisitorsUrl: yup.string().url().required(),
  version: yup.string().required()
})

let _appEnvRaw: Record<string, string> = import.meta.env ?? process.env
let appEnv: AppConfig

export const loadRemoteConfig = async (): Promise<void> => {
  try {
    const response = await fetch('/config.json')
    const data = await response.json()

    if (data) {
      _appEnvRaw = { ..._appEnvRaw, ...data }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Failed to load remote configuration: ${error}`)
  }
}

export const getAppConfig = (env: Record<string, string> = _appEnvRaw): AppConfig => {
  if (!appEnv) {
    appEnv = ConfigSchema.validateSync({
      backOfficeAuthUrl: env.VITE_BACK_OFFICE_AUTH_URL,
      launchDarklyClientId: env.VITE_LAUNCHDARKLY_CLIENT_ID,
      segmentKey: env.VITE_SEGMENT_KEY,
      sentryAuthToken: env.VITE_SENTRY_AUTH_TOKEN,
      sentryDsn: env.VITE_SENTRY_DSN,
      sentryOrg: env.VITE_SENTRY_ORG,
      sentryProject: env.VITE_SENTRY_PROJECT,
      sentryTraceSampleRate: +env.VITE_SENTRY_TRACE_SAMPLE_RATE,
      serviceAggregationsUrl: env.VITE_SERVICE_AGGREGATIONS_URL,
      serviceBackOfficeUrl: env.VITE_SERVICE_BACK_OFFICE_URL,
      serviceCalendarsUrl: env.VITE_SERVICE_CALENDARS_URL,
      serviceInstanceUrl: env.VITE_SERVICE_INSTANCE_URL,
      serviceNylasUrl: env.VITE_SERVICE_NYLAS_URL,
      serviceRequestUrl: env.VITE_SERVICE_REQUEST_URL,
      serviceVisitorsUrl: env.VITE_SERVICE_VISITORS_URL,
      version
    }) satisfies AppConfig
  }

  return appEnv
}
